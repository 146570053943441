import React from 'react'

function WhyChooseE3() {
    return (
        <div>
         	
	<section class="bg-secondary-color background-contain web-totle bg-dark-blue">

		<div class="container">
			<div class="counters">
				<div class="row">
					<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-blue counter-box">
							<div class="counter-numbers counter ">
								<span data-speed="2000" data-refresh-interval="3" data-to="300" data-from="2">300+</span>
								{/* <div class="units">+</div> */}
							</div>
							<h5 class="counter-title">Website Designeds</h5>
						</div>
						<div class="icons-dv blue text-center"><ion-icon name="laptop-outline"></ion-icon></div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-dark-yellow counter-box">
							<div class="counter-numbers counter ">
								<span data-speed="2000" data-refresh-interval="3" data-to="20" data-from="2">30+</span>
								{/* <div class="units">+</div> */}
							</div>
							<h5 class="counter-title">Mobile App</h5>
						</div>
						<div class="icons-dv yellow text-center">
						    <ion-icon name="phone-portrait-outline"></ion-icon>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-red counter-box">
							<div class="counter-numbers counter ">
								<span data-speed="2000" data-refresh-interval="3" data-to="10" data-from="2">10+</span>
								{/* <div class="units">+</div> */}
							</div>
							<h5 class="counter-title">Web Application</h5>
						</div>
						<div class="icons-dv red text-center">
						    <ion-icon name="logo-web-component"></ion-icon>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
						<div class="crumina-module crumina-counter-item c-green counter-box">
							<div class="counter-numbers counter ">
								<span data-speed="2000" data-refresh-interval="3" data-to="100" data-from="2">100+</span>
								{/* <div class="units">+</div> */}
							</div>
							<h5 class="counter-title">Happy Customers</h5>
						</div>
						<div class="icons-dv green text-center">
						   <ion-icon name="person-outline"></ion-icon>
						</div>
					</div>

				</div>
			</div>
		</div>

	</section>
        </div>
    )
}

export default WhyChooseE3